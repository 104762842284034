$width-mobile: 320px;
$width-mobile-container: 290px;
$padding-mobile: 20px;
$text-mobile-font: 16px;
$text-mobile-line: 24px;
$sm-text-mobile-font: 14px;
$sm-text-mobile-line: 24px;
$xs-text-mobile-font: 12px;
$xs-text-mobile-line: 24px;
$heading-h1-mobile-font: 22px;
$heading-h1-mobile-line: 35px;
$heading-h2-mobile-font: 18px;
$heading-h2-mobile-line: 35px;
$heading-h3-mobile-font: 18px;
$heading-h3-mobile-line: 35px;
$heading-h4-mobile-font: 16px;
$heading-h4-mobile-line: 33px;

$width-mobile-album: 568px;
$width-mobile-album-container: 546px;

$width-tablet: 720px;
$width-tablet-container: 690px;
$text-tablet-font: 18px;
$text-tablet-line: 26px;
$sm-text-tablet-font: 15px;
$sm-text-tablet-line: 25px;
$xs-text-tablet-font: 13px;
$xs-text-tablet-line: 25px;

$width-tablet-album: 940px;
$width-tablet-album-container: 910px;

$width-desktop: 1110px;
$width-desktop-container: 1080px;
$padding-desktop: 60px;
$text-desktop-font: 20px;
$text-desktop-line: 28px;
$sm-text-desktop-font: 16px;
$sm-text-desktop-line: 26px;
$xs-text-desktop-font: 14px;
$xs-text-desktop-line: 26px;
$heading-h1-desktop-font: 28px;
$heading-h1-desktop-line: 24px;
$heading-h2-desktop-font: 28px;
$heading-h2-desktop-line: 42px;
$heading-h3-desktop-font: 24px;
$heading-h3-desktop-line: 38px;
$heading-h4-desktop-font: 20px;
$heading-h4-desktop-line: 34px;

$main-text-color: #031d5b;
$text-grey: #5b6c94;
$main-color: #031d5b;
$white: #ffffff;
$button-common-color: #0070ff;
$main-theme-color: #0070ff;